import React from 'react'

import logo from '../static/logo.png'

const Contacts: React.FC<{}> = props => {
	return (
		<footer>
			<div className="section">

            <img src={logo} className="logo" alt="Glory Outreach Ministries" /><br />
			<address>
				<b>Glory Outreach Ministries</b><br />
				Email: info@gloryoutreach.org<br />
				PO Box 1-30500 Lodwar Kenya<br />
				Tel: +254 (0) 713 679 671 / +254 (0) 743 964 190 - Presiding Bishop<br />
			</address>
			<small>Glory Outreach Ministries. Copy right (c)2020. All rights reserved.</small>
			</div>
		</footer>
	)
}

export default Contacts
