import React from 'react'

import book from '../static/imgs/6.jpg'

const About: React.FC<{}> = props => { console.log(props)

	return ( 
		<section>
			<h1 className="title">Glory Outreach Ministries</h1> 
			<img src={book} alt="Glory Outreach" className="about-cover"/> 
			<p>

				Glory Outreach Ministry is duly registered as a Missionary Ministry in
				Turkana Kenya. The ministry serves among people with little or no
				education in Kenya, reaching them in some of the most remote villages in
				North eastern Kenya, with the Gospel of The LORD Jesus Christ. As a
				ministry, we endeavor to reach, fellowship and share the grace of the LORD
				Jesus Christ, and the love of God, and the fellowship of the Holy Spirit,
				with as many of our people as God enables us, and as many as God brings to
				us. We have over time traversed Turkana County, West Pokot County,
				southern Sudan, eastern Uganda, and elsewhere, planting churches and
				supporting outreach ministries.

				In most of the places we have visited, the peace of God has been notably
				manifest in the lives of the reached peoples. Through partnership with
				others, we have also helped get deliver portable (drinking) water,
				distribute food and non-food items (clothes, household items) and offered
				agricultural support to some non-migrating populations.
			</p>
			<h5>Partners</h5>
			<p>
				Glory Outreach Ministry (GOM) has both international and local partners
				which cooperate with her in reaching out to the least reached. The
				international partners include The Global Disciples, Livestreams
				International, and the local partners are the local Chiefs in Turkana and
				West Pokot Counties, National Government and The Turkana County
				Government. Through this active cooperation with international and local
				partners, tremendously successes have been recorded in the development of
				the local church, and various communities in Turkana and West Pokot. The
				development and humanitarian activities implemented in these areas
				included Missionary Outreach, church planting, church planters training,
				Emergency Response, Water Drilling, Medical Camps, and agricultural
				activities.
			</p>
			<h5>Missionaries training</h5>
			<p>
				Over 50 graduates of the Lodwar International School of Missions (LISOM)
				and Turkana Interdenominational School of Missions (TISOM), founded by
				indigenous missionary Benson Ekuwom, are currently serving as Pastors,
				Evangelists and Missionaries in remote villages of Northern Kenya and near
				the borders of Uganda, Sudan and Ethiopia.
			</p>
			<h5>Current Projects</h5>
			<p>
				Efforts are ongoing to fundraise resources for helping identify and
procure the expertise and resources to drill wells in remote ‘interior’
community centers that will provide life-sustaining water for thousands of
people, many who to date, have migrated away in search of food and water.
			</p>
</section> ) }

export default About
