import React from 'react';

import logo from './static/logo.png';
import './App.css';

import About from './pages/About'
import ContactPage from './pages/Contact'

function App() {
  return (
    <div className="App">
        <header className="App-header">
          <section>
            <img src={logo} className="logo" alt="Glory Outreach Minitiries" />
            <h1>That thy way may be known upon earth, thy saving health among all nations. Ps. 67:2</h1>
            <a href="mailto:info@gloryoutreach.org"><button className="cta">I want to be part of the ministry</button></a>
          </section>
        </header>
        <About />
        <ContactPage />
    </div>
  );
}

export default App;
